// 서버사이드에서 쿠키 파싱
export const parseCookies = (cookie = '') => {
  return cookie
    .split(';')
    .map((v) => v.split('='))
    .map(([k, ...vs]) => [k, vs.join('=')])
    .reduce((acc, [k, v]) => {
      acc[k.trim()] = decodeURIComponent(v)
      return acc
    }, {})
}

// object를 string으로 이어주는 함수
export const serialize = (obj) => {
  let value = ''

  Object.entries(obj).map((item) => {
    if (value === '') value += `${item[0]}=${item[1]}`
    else value += `&${item[0]}=${item[1]}`
  })

  return value
}

// object의 key 오름차순으로 정렬하는 함수
export const ksort = (obj) => {
  const keys = Object.keys(obj).sort(),
    sortedObj = {}

  for (let i in keys) {
    sortedObj[keys[i]] = obj[keys[i]]
  }

  return sortedObj
}
