import axios from 'axios'

const host = 'https://chatapi.redtable.global'

const auth = {
  username: process.env.NEXT_PUBLIC_REDTABLE_CHATAPI_USERNAME,
  password: process.env.NEXT_PUBLIC_REDTABLE_CHATAPI_PASSWORD,
}

// POST : 상점조회
export const postKtcPoiByid3 = async (data, env = 'client') => {
  // const data = {
  //     store_id
  //     lang,
  // }

  const setParams = (data) => {
    return {
      poi: data,
      auth,
    }
  }

  return await axios
    .post(
      `${env === 'client' ? '/chatapi' : host}/ktc-poi-byid3`,
      setParams(data)
    )
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : RWG 매장 길
export const postRwgStoreRoad = async (data, env = 'client') => {
  // const data = {
  //     store_id
  //     lang,
  // }

  const setParams = (data) => {
    return {
      request: data,
      auth,
    }
  }

  return await axios
    .post(
      `${env === 'client' ? '/chatapi' : host}/rwg-store-road`,
      setParams(data)
    )
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : RWG 매장 정보
export const postRwgStoreInfo = async (data, env = 'client') => {
  // const data = {
  //     store_id
  //     lang,
  // }

  const setParams = (data) => {
    return {
      request: data,
      auth,
    }
  }

  return await axios
    .post(
      `${env === 'client' ? '/chatapi' : host}/rwg-store-info`,
      setParams(data)
    )
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : RWG 매장 상품 정보
export const postRwgProductInfo = async (data, env = 'client') => {
  // const data = {
  //     store_id
  //     lang,
  // }

  const setParams = (data) => {
    return {
      request: data,
      auth,
    }
  }

  return await axios
    .post(
      `${env === 'client' ? '/chatapi' : host}/rwg-product-info`,
      setParams(data)
    )
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : RWG 매장 상품 정보
export const postRwgStoreLandmark = async (data, env = 'client') => {
  // const data = {
  //     store_id
  //     lang,
  // }

  const setParams = (data) => {
    return {
      request: data,
      auth,
    }
  }

  return await axios
    .post(
      `${env === 'client' ? '/chatapi' : host}/rwg-store-landmark`,
      setParams(data)
    )
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : 병원 예약가능 날짜/시간 조회 (Client)
export const postRwgHospitalReserveTimeCheck = async (data) => {
  // const data = {
  //   store_id,
  //   date, // 20240913
  //   time, // 1300
  //   lang
  // }

  const setParams = (data) => {
    return {
      request: data,
      auth,
    }
  }

  return axios
    .post(`/chatapi/test/rwg-hospital-reserve-timecheck`, setParams(data))
    .then((res) => res.data)
    .catch((err) => console.error(err))
}
