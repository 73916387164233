export const factorizeOption = (option) => {
  let arr = []

  for (let i = 16; i >= 0; i--) {
    const pow = Math.pow(2, i)
    if (option >= pow) {
      arr.push(pow)
      option -= pow
    }
  }

  return arr.reverse()

  // export const getOption = number => {
  //   let arr = [];
  //   for (let i = 0; i < 17; i++) {
  //     arr.push(number & Math.pow(2, i));
  //   }
  //   return arr;
  // };
}

export const changeDay = (lang, day) => {
  switch (lang) {
    case 'ko':
      switch (day) {
        case 'Sun':
          return '일요일'
        case 'Mon':
          return '월요일'
        case 'Tue':
          return '화요일'
        case 'Wed':
          return '수요일'
        case 'Thu':
          return '목요일'
        case 'Fri':
          return '금요일'
        case 'Sat':
          return '토요일'
        default:
          return null
      }
    case 'ja':
      switch (day) {
        case 'Sun':
          return '日曜日'
        case 'Mon':
          return '月曜日'
        case 'Tue':
          return '火曜日'
        case 'Wed':
          return '水曜日'
        case 'Thu':
          return '木曜日'
        case 'Fri':
          return '金曜日'
        case 'Sat':
          return '土曜日'
        default:
          return null
      }
    case 'zh-Hans':
      switch (day) {
        case 'Sun':
          return '周日'
        case 'Mon':
          return '周一'
        case 'Tue':
          return '周二'
        case 'Wed':
          return '周三'
        case 'Thu':
          return '周四'
        case 'Fri':
          return '周五'
        case 'Sat':
          return '周六'
        default:
          return null
      }
    case 'es':
      switch (day) {
        case 'Sun':
          return 'Domingo'
        case 'Mon':
          return 'Lunes'
        case 'Tue':
          return 'Martes'
        case 'Wed':
          return 'Miércoles'
        case 'Thu':
          return 'Jueves'
        case 'Fri':
          return 'Viernes'
        case 'Sat':
          return 'Sábado'
        default:
          return null
      }
    case 'en':
    default:
      return day
  }
}
