import { css } from '@emotion/react'

import '@fontsource/noto-sans-kr/300.css'
import '@fontsource/noto-sans-kr/500.css'
import '@fontsource/noto-sans-kr/700.css'

import '@fontsource/noto-sans-jp/300.css'
import '@fontsource/noto-sans-jp/500.css'
import '@fontsource/noto-sans-jp/700.css'

import '@fontsource/noto-sans-sc/300.css'
import '@fontsource/noto-sans-sc/500.css'
import '@fontsource/noto-sans-sc/700.css'

const font = css`
  * {
    font-family: 'Noto Sans KR', 'Noto Sans JP', 'Noto Sans SC', 'Roboto',
      sans-serif;
  }

  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  label,
  button,
  article {
    font-weight: 400;
    margin: 0;
  }

  th,
  td,
  option {
    font-weight: 300;
  }
`

export default font
