import axios from 'axios'
import moment from 'moment/moment'

const host = process.env.NEXT_PUBLIC_API_HOST

// GET : 배너
export const getFrontBanner = async (data, env = 'client') => {
  // const data = {
  //   s_channel,
  //   s_lang
  // }

  const params = {
    s_type: 'layer',
    s_status: 'normal',
    s_file_type: 'mo',
    s_date: moment().format('yyyy-MM-DD'),
    ...data,
  }

  return await axios

    .get(`${env === 'client' ? '/apiv1' : host}/front/banner`, { params })
    .then((res) => res)
    .catch((err) => err.response.status)
}

// GET : Must-eat/Main
export const getFrontMusteatMain = async (data, env = 'client') => {
  // data = {
  //   s_commercial_area_id,
  //   s_channel,
  //   s_lang
  // }

  return await axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/must-eat/main`, {
      params: data,
    })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// GET : Must-eat
export const getFrontMusteat = async (data, env = 'client') => {
  // data = {
  //   s_commercial_area_id,
  //   s_channel,
  //   s_must_eat_id,
  //   s_lang,
  //   offset
  // }

  return await axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/must-eat`, {
      params: data,
    })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// GET : Select-city
export const getCommonSelectcity = async (data, env = 'client') => {
  // const data = {
  //   s_lang,
  //   s_channel
  // }

  return await axios
    .get(`${env === 'client' ? '/apiv1' : host}/common/select-city`, {
      params: data,
    })
    .then((res) => res.data.data)
    .catch((err) => err.response.status)
}
