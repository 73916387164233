import React, { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Cookies } from 'react-cookie'
import { Provider } from 'react-redux'
import wrapper from '../store'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { GoogleAnalytics } from '@next/third-parties/google'

import {
  getFrontCart,
  getFrontUserBookmark,
  getFrontUserProfile,
  postFrontUserNextlogin,
  postFrontUserTokencheck,
} from 'api'
import { setAuth } from 'store/auth'
import { setCart } from 'store/cart'
import { setWishlist } from 'store/wishlist'
import { Global } from '@emotion/react'
import global from 'style/globalStyle'
import ErrorBoundary from 'components/ErrorBoundary'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { DEFAULT_LANG } from 'constants/lang'
import {
  generateMD5Hash,
  switchChannel,
  switchLocale,
  switchWhitelabel,
} from 'lib'
import { setCookie } from 'cookies-next'
import { cookieSetOptions } from 'constants/coockie'

const App = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest)

  const router = useRouter()
  const dispatch = store.dispatch
  const cookies = new Cookies()

  const lang = cookies.get('lang')
  const token = cookies.get('token')
  const user_id = cookies.get('user_id')

  const { i18n } = useTranslation()
  const { languages } = i18n

  useEffect(() => {
    history.scrollRestoration = 'manual'

    // ========== 세션 체크 로직 ==========
    if (token) {
      cookies.remove('not_login_user_id')

      postFrontUserNextlogin({
        id: user_id,
        apiToken: token,
      }).then((res) => {
        if (res.status !== 'success') {
          cookies.remove('token', { path: '/' })
          cookies.remove('user_id', { path: '/' })
        } else {
          // 인증정보 리덕스에 저장
          dispatch(setAuth({ token, id: user_id }))

          // 카트정보 리덕스에 저장
          dispatch(
            setCart({
              count: res.cart.length,
              store: res.cart.length > 0 ? res.cart[0].store_id : null,
              // is_package:
              //   res.data.length > 0 ? res.data[0].is_package === 1 : false,
              is_package: false,
            })
          )

          // 북마크 리덕스에 저장
          const wishlist = res.bookmark.map((item) =>
            item.product_id ? item.product_id : item.store_id
          )
          dispatch(setWishlist({ wishlist }))
        }
      })
    } else {
      if (!cookies.get('not_login_user_id'))
        cookies.set('not_login_user_id', generateMD5Hash(), cookieSetOptions)
    }

    // ========== i18n 관련 세팅 ==========
    if (!languages) return
    const locale = switchLocale(window.location.pathname.split('/')[1])

    if (window.location.pathname.includes('rwg')) return

    if (lang === 'undefined') {
      return cookies.set('lang', DEFAULT_LANG, cookieSetOptions)
    }

    if (locale === '') {
      if (lang) {
        router.push(router.asPath, router.asPath, {
          locale: lang,
          scroll: false,
        })
      } else {
        cookies.set('lang', DEFAULT_LANG, cookieSetOptions)
        router.push(router.asPath, router.asPath, {
          locale: DEFAULT_LANG,
          scroll: false,
        })
      }
    } else {
      cookies.set('lang', locale, cookieSetOptions)
      router.push(router.asPath, router.asPath, {
        locale: locale,
        scroll: false,
      })
    }
  }, [])

  return (
    <>
      <Global styles={global} />

      <Head>
        <link rel="icon" href="/favicon.png" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <title>
          {switchWhitelabel(props.pageProps.channel).title ?? 'REDTABLE'}
        </title>
      </Head>
      <ErrorBoundary>
        <GoogleOAuthProvider
          clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
        >
          <GoogleAnalytics
            gaId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_GTMID}
          />
          <Provider store={store}>
            <Component {...props.pageProps} />
          </Provider>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </>
  )
}

App.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {}

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  const isServer = typeof window === 'undefined'
  let subdomain

  if (isServer) {
    subdomain =
      process.env.NODE_ENV === 'development'
        ? process.env.NEXT_PUBLIC_SUBDOMAIN
        : ctx.req
        ? ctx.req.headers.host.split('.')[0]
        : 'redtable'
  } else {
    subdomain =
      process.env.NODE_ENV === 'development'
        ? process.env.NEXT_PUBLIC_SUBDOMAIN
        : window.location.hostname.split('.')[0]
  }

  setCookie('subdomain', subdomain, { req: ctx.req, res: ctx.res })

  const channel = switchChannel(subdomain)
  setCookie('channel', channel, { req: ctx.req, res: ctx.res })

  pageProps = { ...pageProps, channel }
  return {
    pageProps,
  }
}

export default appWithTranslation(App)
