import axios from 'axios'
const host = process.env.NEXT_PUBLIC_API_HOST

// GET : 질문목록 조회
export const getFrontCs = async (token, env = 'client') => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: 300,
    },
  }

  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/cs`, config)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// GET : 질문조회
export const getFrontCsId = async (data, env = 'client') => {
  // data = {
  //   token,
  //   s_user_cs_id
  // }

  const { token, s_user_cs_id } = data
  const headers = { Authorization: `Bearer ${token}` }

  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/cs/${s_user_cs_id}`, {
      headers,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// GET : CS 파일 조회
export const getFrontCsFile = async (data, env = 'client') => {
  // data = {
  //   token,
  //   s_user_cs_id
  // }

  const { token, s_user_cs_id } = data
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      s_user_cs_id,
    },
  }

  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/cs/file`, config)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// DELETE : CS 삭제 (Client)
export const deleteFrontCsId = async (data) => {
  // data = {
  //   token,
  //   s_user_cs_id
  // }

  const { token, s_user_cs_id } = data
  const headers = { Authorization: `Bearer ${token}` }

  return axios
    .delete(`/apiv1/front/cs/${s_user_cs_id}`, { headers })
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// POST : CS 등록 (Client)
export const postFrontCs = async (data) => {
  // data = {
  //   token,
  //   type,
  //   phone,
  //   content
  // }

  const { token, ...csCotents } = data
  const params = {
    use_yn: 'y',
    status: 'ready',
    ...csCotents,
  }
  const headers = { Authorization: `Bearer ${token}` }

  return axios
    .post(`/apiv1/front/cs`, params, { headers })
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// POST : CS 파일 등록 (Client)
export const postFrontCsFile = async (data, formData) => {
  // data = {
  //   token,
  //   user_cs_id
  // }

  const { token, user_cs_id } = data
  formData.append('user_cs_id', user_cs_id)

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios
    .post(`/apiv1/front/cs/file`, formData, config)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}
