import axios from 'axios'
const host = process.env.NEXT_PUBLIC_API_HOST

// POST : 토큰세션 유효체크
export const postFrontUserTokencheck = async (
  id,
  api_token,
  env = 'client'
) => {
  // 피라미터를 객체로 받으면 시차가 생겨 CORS 현상이 발생함
  const data = {
    id,
    api_token,
  }

  return await axios
    .post(`${env === 'client' ? '/apiv1' : host}/front/user/token-check`, {
      ...data,
    })
    .then((res) => res.data)
}

// GET : Timezone 조회
export const getCommonTimezone = async (env = 'client') => {
  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/common/timezone`)
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : 로그인 (Client)
export const postFrontLogin = async (data) => {
  // data = {
  //   email,
  //   password
  // }

  return await axios
    .post(`/apiv1/front/login`, data)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// GET : 프로필 조회
export const getFrontUserProfile = async (token, env = 'client') => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/user/profile`, config)
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// GET : 포인트 이용내역 조회
export const getFrontPointLog = async (token, env = 'client') => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: 300,
    },
  }

  return await axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/point/log`, config)
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : 쿠폰 등록 (Client)
export const postFrontCoupon = async (data) => {
  const { token, serial } = data

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  return await axios
    .post(`/apiv1/front/coupon`, { serial }, config)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// POST : 회원가입 (Client)
export const postFrontRegister = async (data) => {
  // const data = {
  //   name,
  //   email,
  //   password,
  //   password_confirmation,
  //   phone,
  //   language,
  //   timezone
  // }

  return await axios
    .post(`/apiv1/front/register`, data)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// POST :  회원정보 수정 (Client)
export const postFrontUserProfile = async (data) => {
  // const data = {
  //   password,
  //   timezone,
  //   name,
  //   phone
  // }
  const { token, ...params } = data

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  return await axios
    .post(`/apiv1/front/user/profile`, params, config)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// POST : 비밀번호 변경 (Client)
export const postFrontUserPasswordchange = async (data) => {
  // const data = {
  //   token,
  //   password_old,
  //   password,
  //   confirm_password,
  // }

  const { token, ...params } = data

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  return await axios
    .post(`/apiv1/front/user/password-change`, params, config)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// POST : 소셜로그인 (Client)
export const postFrotnLoginProvider = async (data) => {
  // const data = {
  //   sns_id,
  //   sns_provider,
  //   name, // 필수 아님
  //   email  // 필수 아님
  // }

  return await axios
    .post(`/apiv1/front/login/provider`, data)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// POST : 비밀번호 초기화 이메일 발송 (Client)
export const postFrontUserForgot = async (data) => {
  // const data = {
  //   email,
  //   url
  // }

  return axios
    .post(`/apiv1/front/user/forgot`, data)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// POST : 비밀번호 초기화 (client)
export const postFrontUserPasswordReset = async (data) => {
  // const data = {
  //   email,
  //   password_token,
  //   password,
  //   confirm_password
  // }

  return await axios
    .post(`/apiv1/front/user/password/reset`, data)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

// GET : faq
export const getFrontFaq = async (data, env = 'client') => {
  // const data = {
  //   s_lang
  // }

  const config = {
    params: {
      s_status: 'normal',
      s_order1: 'T10.sorting',
      s_order2: true,
      limit: 100,
      ...data,
    },
  }

  return await axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/faq`, config)
    .then((res) => res.data)
    .catch((err) => err.response.data)
}

export const postRwgNotLoginCart = async (data) => {
  // const {
  //   not_login_user_id,
  //   api_token
  // }

  return axios
    .post('/apiv1/rwg/not-login-cart', data)
    .then((res) => res.data)
    .catch((err) => console.error(err))
}

export const postFrontUserNextlogin = async (data) => {
  // const data = {
  //  id,
  //  apiToken
  // }

  return axios
    .post('/apiv1/front/user/nextLogin', data)
    .then((res) => res.data)
    .catch((err) => console.error(err))
}
