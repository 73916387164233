import moment from 'moment'
import { replacePrice } from 'lib'

export const promotionBanner = (data, lang) => {
  const { max_discount_rate, max_discount_price, date_to } = data
  const replacePrice = (price) => parseInt(price).toLocaleString('ko-kr')

  const target = {
    ko: (
      <p>
        <span className="discount">{max_discount_rate * 100}% 할인</span> (최대{' '}
        {replacePrice(max_discount_price)}원) {date_to}까지
      </p>
    ),
    en: (
      <p>
        <span className="discount">{max_discount_rate * 100}% discount</span>
        (up to {replacePrice(max_discount_price)} won) Until{' '}
        {moment(date_to).format('MMM Do, YYYY')}
      </p>
    ),
    ja: (
      <p>
        <span className="discount">{max_discount_rate * 100}%割引</span>
        (最大で{replacePrice(max_discount_price)}ウォン) {date_to}まで
      </p>
    ),
    'zh-Hans': (
      <p>
        <span className="discount">打{max_discount_rate * 100}%</span>
        (最多可减{replacePrice(max_discount_price)}韩元) 截止{date_to}
      </p>
    ),
    es: (
      <p>
        <span className="discount">{max_discount_rate * 100}% discount</span>
        (up to {replacePrice(max_discount_price)} won) Until{' '}
        {moment(date_to).format('MMM Do, YYYY')}
      </p>
    ),
  }

  return target[lang]
}
