import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  wishlist: null,
}

const wishlist = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setWishlist: (_, action) => ({
      wishlist: action.payload.wishlist,
    }),
    insertWishlist: (state, action) => ({
      wishlist: [...state.wishlist, action.payload.wishlist],
    }),
    deleteWishlist: (state, action) => ({
      wishlist: state.wishlist.filter((e) => e !== action.payload.wishlist),
    }),
  },
})

export const { setWishlist, insertWishlist, deleteWishlist } = wishlist.actions

export default wishlist.reducer
