import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lat: null,
  lng: null,
}

const gps = createSlice({
  name: 'gps',
  initialState,
  reducers: {
    setGps: (_, action) => ({
      lat: action.payload.lat,
      lng: action.payload.lng,
    }),
  },
})

export const { setGps } = gps.actions

export default gps.reducer
