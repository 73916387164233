import { css } from '@emotion/react'
import font from './font'

import 'material-icons/iconfont/outlined.css'

const global = css`
  ${font}

  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    max-width: 480px;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    background-color: white;
  }

  ul,
  ol,
  li {
    list-style: none;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  main {
    padding-bottom: 72px;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .clamp1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .clamp2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .display-flex {
    display: flex;
    align-items: center;
  }

  .display-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .align-center {
    display: flex;
    align-items: center;
  }

  .space-between {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
  }

  .display-flex-end {
    display: flex;
    align-items: flex-end;
  }

  .display-flex-direction {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .display-flex-direction-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  // swiper.js
  .swiper-pagination-bullet-active {
    background-color: #ff4a52;
    text-decoration: dashed;
  }

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-pagination-fraction {
    color: #ff4a52;
    text-shadow: #b3343a 0px 1px;
  }

  .swiper-button-prev {
    padding-left: 8px;
  }

  .swiper-button-next {
    padding-right: 8px;
  }

  // antd-destyle
  .ant-form-item {
    margin-bottom: 0;
  }

  .rc-virtual-list-scrollbar-thumb {
    width: 100%;
    height: 128px;
    top: 0px;
    left: 0px;
    position: absolute;
    background: rgba(255, 74, 82, 0.5) !important;
    border-radius: 99px;
    cursor: pointer;
    user-select: none;
  }
`

export default global
