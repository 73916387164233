import axios from 'axios'
const host = process.env.NEXT_PUBLIC_API_HOST

// GET : 주문내역 조회
export const getFrontOrder = async (token, env = 'client') => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit: 300,
    },
  }

  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/order`, config)
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// GET : 주문내역 상세조회
export const getFrontOrderId = async (data, env = 'client') => {
  // data = {
  //   token,
  //   order_no,
  //   s_lang
  // }

  const { token, order_no, s_lang = 'ko' } = data
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      s_lang,
    },
  }

  return axios
    .get(
      `${env === 'client' ? '/apiv1' : host}/front/order/${order_no}`,
      config
    )
    .then((res) => res.data)
    .catch((err) => err)
}

// DELETE : 주문취소 (Client)
export const deleteFrontOrder = async (data) => {
  // data = {
  //   token,
  //   order_no
  // }

  const { token, order_no } = data
  const headers = { Authorization: `Bearer ${token}` }

  return await axios
    .delete(`/apiv1/front/order/${order_no}`, { headers })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// DELETE : 부분취소 (Client)
export const deleteFrontOrderId = async (data) => {
  // data = {
  //   token,
  //   order_no,
  //   order_product_id
  // }

  const { token, order_no, order_product_id } = data
  const headers = { Authorization: `Bearer ${token}` }

  return await axios
    .delete(`/apiv1/front/order/${order_no}/${order_product_id}`, { headers })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : 주문정보 및 결제정보 저장
export const postFrontOrder = async (data) => {
  const { token, ...params } = data
  const headers = { Authorization: `Bearer ${token}` }

  // const data = {
  //   name,
  //   phone,
  //   order_type: 'general',
  //   payment_gateway: payment_method,
  //   reserve_day,
  //   reserve_time: moment().hour(hour).minute(minute).format("HH:mm"),
  //   memo,
  //   person,
  //   order_lang,
  //   rwg_order_no,
  //   card_price: totalPrice - user_point,
  //   kcp_price,
  //   barogage_price,
  //   redtable_price,
  //   promotion_price,
  //   email
  // }

  return axios
    .post(`/apiv1/front/order`, params, { headers })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : 결제정보 저장 (Client)
// export const postFrontOrderIdOrderpay = async(token, order_no, payment_gateway = "nicepay") => {
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }

//   return await axios
//     .post(`/apiv1/front/order/${order_no}/order-pay`, { payment_gateway }, config)
//     .then(res => res.data)
//     .catch((err) => err.response.status)
// }

// POST : 결제정보 조회
export const postFrontOrderIdOrderpaylist = async (data, env = 'client') => {
  // data = {
  //   token,
  //   order_no
  // }

  const { token, order_no } = data
  const headers = { Authorization: `Bearer ${token}` }

  return await axios
    .post(
      `${
        env === 'client' ? '/apiv1' : host
      }/front/order/${order_no}/order-pay-list`,
      null,
      { headers }
    )
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : 나이스페이 결제 콜백 (Client)
export const postFrontOrderCompleteNicepay = async (data) => {
  // const data = {
  //   nextAppURL,
  //   transaction_id : TxTid,
  //   authToken,
  //   pay_method, // CARD | BANK | VBANK
  //   order_no : Moid,
  //   price : Atomics,
  //   netCancelURL
  // }

  const { token, ...params } = data
  const headers = { Authorization: `Bearer ${token}` }

  return await axios
    .post(`/apiv1/front/order/complete/nicepay`, params, { headers })
    .then((res) => res.data)
    .catch((err) => err)
}

// POST : 엑심베이 결제 후 order-pay 업데이트 (Server)
export const postFrontOrderCompleteEximbay = async (data) => {
  // const data = {
  //   token
  //   transaction_id,
  //   order_no,
  //   price
  // }

  const { token, ...params } = data
  const headers = { Authorization: `Bearer ${token}` }

  return axios
    .post(`${host}/front/order/complete/eximbay`, params, { headers })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : ICB 결제 후 order-pay 업데이트 (server)
export const postFrontOrderCompleteIcb = async (data) => {
  // const data = {
  //   transaction_id,
  //   payment_gateway,
  //   order_no,
  //   price
  // }

  const { token, ...params } = data
  const headers = { Authorization: `Bearer ${token}` }

  return axios
    .post(`${host}/front/order/complete/icb`, params, { headers })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}
