// 채널 추가하는 방법
// 1. switchChannel 함수에 추가
// 2. switchWhitelabel 함수에 로고, 배너, 기타 기본값 입력
// (선택사항) 3. KTC 배너 노출하려면 checkKTC 함수에도 추가

// 채널 전환
export const switchChannel = (channel) => {
  if (process.env.NEXT_PUBLIC_API_HOST === 'https://apiv1.redtable.global') {
    switch (channel) {
      case 'redtable':
      case 'barogage':
      case 'songjeongsurfvillage':
      case 'ulsan':
      case 'incheon':
      case 'myeongdong':
        return channel
      default:
        return 'redtable'
    }
  } else {
    switch (channel) {
      case 'barogage-dev':
      case 'songjeongsurfvillage-dev':
      case 'ulsan-dev':
      case 'incheon-dev':
      case 'myeongdong-dev':
        return channel.replace('-dev', '')
      case 'user2-dev':
        return 'redtable'
      default:
        return 'redtable'
    }
  }
}

// 화이트레이블 전환
export const switchWhitelabel = (channel, lang) => {
  const isApiV1 =
    process.env.NEXT_PUBLIC_API_HOST === 'https://apiv1.redtable.global'

  switch (channel) {
    case 'barogage':
      return {
        logo: '/asset/image/logo-redtable-hor.png',
        banner: `/asset/banner/v2/ktc_banner_${lang}_240618.jpg`,
        cat2: isApiV1 ? 2 : 2,
        s_commercial_area_id: isApiV1 ? 231 : 198,
        initialMusteat: isApiV1 ? 8837 : 1894,
        mustgo: true,
      }
    case 'songjeongsurfvillage':
      return {
        logo: '/asset/whitelabel/logo-songjeong.png',
        banner: `/asset/whitelabel/main_banner_songjeong_${lang}.png`,
        cat2: isApiV1 ? 28 : 2,
        s_commercial_area_id: isApiV1 ? 239 : 198,
        initialMusteat: isApiV1 ? 8852 : 1894,
        mustgo: false,
        title: '송정서프빌리지',
      }
    case 'ulsan':
      return {
        logo: '/asset/image/logo-redtable-hor.png',
        banner: '/asset/whitelabel/main_banner_ulsan3.jpg',
        cat2: isApiV1 ? 77 : 2,
        s_commercial_area_id: isApiV1 ? 223 : 198,
        initialMusteat: isApiV1 ? 8830 : 1894,
        mustgo: true,
      }
    case 'incheon':
      return {
        logo: '/asset/whitelabel/logo-incheon-hor.png',
        banner: '/asset/whitelabel/main_banner_incheon.jpg',
        cat2: isApiV1 ? 54 : 2,
        s_commercial_area_id: isApiV1 ? 236 : 198,
        initialMusteat: isApiV1 ? 8840 : 1894,
        mustgo: true,
      }
    case 'myeongdong':
      return {
        logo: '/asset/image/logo-redtable-hor.png',
        banner: `/asset/banner/v2/main_banner_${lang}.jpg`,
        cat2: isApiV1 ? 2 : 2,
        s_commercial_area_id: isApiV1 ? 231 : 198,
        initialMusteat: isApiV1 ? 8837 : 1894,
        mustgo: true,
      }
    case 'redtable':
    default:
      return {
        logo: '/asset/image/logo-redtable-hor.png',
        banner: `/asset/banner/v2/ktc_banner_${lang}_240618.jpg`,
        cat2: isApiV1 ? 2 : 2,
        s_commercial_area_id: isApiV1 ? 231 : 198,
        initialMusteat: isApiV1 ? 8837 : 1894,
        mustgo: true,
      }
  }
}

// KTC 배너
export const checkKTC = (channel) => {
  switch (channel) {
    case 'redtable':
    case 'barogage':
      return true
    default:
      return false
  }
}
