// NEXT Props : CORS 자동으로 처리됨 -> host 안적어도 됨
// 클라이언트 : CORS 처리 해줘야함 -> host 적어야함

export * from './main'
export * from './mypage'
export * from './store'
export * from './product'
export * from './cart'
export * from './wishlist'
export * from './order'
export * from './cs'
export * from './barogage'
export * from './ktc'
export * from './rwg'

export class ElasticSearchQuery {
  constructor(type, offset, keyword, lang) {
    this.size = 10
    this.from = offset * 10
    this.query = {
      function_score: {
        query: {
          bool: {
            must: [
              {
                match: {
                  [type === 'store'
                    ? 'store_translate_name'
                    : 'p_tr_name']: `*${keyword}*`,
                },
              },
              {
                match: {
                  lang,
                },
              },
              {
                match: {
                  category: 'food',
                },
              },
            ],
          },
        },
        functions: [
          {
            filter: {
              term: {
                [type === 'store' ? 'status' : 'contract_status']: 'normal',
              },
            },
            weight: 2,
          },
        ],
        boost_mode: 'sum',
      },
    }
  }
}
