// import { createSlice } from '@reduxjs/toolkit'

// const initialState = {
//   count: 0,
//   store: null,
// }

// const cart = createSlice({
//   name: 'cart',
//   initialState,
//   reducers: {
//     setCart: (_, action) => ({
//       count: action.payload.count,
//       store: action.payload.store,
//     }),
//     minusCart: (state) => ({
//       ...state,
//       count: state.count - 1,
//     }),
//     plusCart: (state) => ({
//       ...state,
//       count: state.count + 1,
//     }),
//     storeCart: (state, action) => ({
//       ...state,
//       store: action.payload.store,
//     }),
//   },
// })

// export const { setCart, minusCart, plusCart, storeCart } = cart.actions

// export default cart.reducer

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  count: 0,
  store: null,
  is_package: false,
}

const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart: (_, action) => ({
      count: action.payload.count,
      store: action.payload.store,
      is_package: action.payload.is_package,
    }),
    minusCart: (state) => ({
      ...state,
      count: state.count - 1,
    }),
    plusCart: (state) => ({
      ...state,
      count: state.count + 1,
    }),
    storeCart: (state, action) => ({
      ...state,
      store: action.payload.store,
    }),
    countCart: (state, action) => ({
      ...state,
      count: action.payload.count,
    }),
    packageCart: (state, action) => ({
      ...state,
      is_package: action.payload.is_package,
    }),
    initCart: () => ({
      count: 0,
      store: null,
      is_package: false,
    }),
  },
})

export const {
  setCart,
  minusCart,
  plusCart,
  storeCart,
  countCart,
  packageCart,
  initCart,
} = cart.actions

export default cart.reducer
