export const switchHeader = (path) => {
  switch (path) {
    case '/':
    case '/signin':
      return 'main'
    case '/wishlist':
    case '/select-city':
    case '/order':
    case '/mypage':
      return 'mainDepth'
    default:
      return 'Default'
  }
}
