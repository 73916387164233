import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  token: null,
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (_, action) => ({
      id: action.payload.id,
      token: action.payload.token,
    }),
  },
})

export const { setAuth } = auth.actions

export default auth.reducer
