import { ElasticSearchQuery } from 'api'
import axios from 'axios'
const host = process.env.NEXT_PUBLIC_API_HOST

// GET : 상점검색
export const getFrontStore = async (data, env = 'client') => {
  // data = {
  //   s_lang,
  //   offset,
  //   s_keyword
  // }

  const params = {
    s_order1: 'T10.sorting',
    s_order2: 'desc',
    s_status: 'normal',
    s_store_status: 'normal',
    limit: 10,
    ...data,
  }

  return await axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/store`, { params })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// GET : 엘라스틱 서치(상점)
export const postElasticSearchStore = async (data, env = 'client') => {
  const query = new ElasticSearchQuery(
    'store',
    data.offset,
    data.s_keyword,
    data.s_lang
  )

  return await axios
    .post(
      `${
        env === 'client'
          ? '/elastic'
          : process.env.NEXT_PUBLLIC_ELASTIC_SEARCH_HOST
      }/store_index/_search`,
      query
    )
    .then((res) => {
      const result = res.data.hits.hits

      let arr = []
      result.map((item) => arr.push(item._source))
      return arr
    })
    .catch((err) => console.log(err))
}

// GET : 특정 상점 조회
export const getFrontStoreId = async (data, env = 'client') => {
  // const data = {
  //   storeId
  //   s_lang,
  //   offset
  // }

  const { storeId, ...params } = data

  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/store/${storeId}`, {
      params,
    })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// GET : 방문자 조회
export const getFrontLiveEvent = async (data, env = 'client') => {
  // data = {
  //   s_lang,
  //   s_store_id,
  //   offset
  // }

  const params = {
    s_orde1: 'created_at_utc',
    s_order2: 1,
    ...data,
  }

  return await axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/live-event`, { params })
    .then((res) => res.data.data)
    .catch((err) => err.response.status)
}

// GET : 영업가능일 조회
export const getFrontStoreIdBusinessday = async (data, env = 'client') => {
  const { store_id, date } = data

  return await axios
    .get(
      `${
        env === 'client' ? '/apiv1' : host
      }/front/store/${store_id}/business-day/${date}`
    )
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// GET : 오더 ID로 상점정보 조회 (Client)
export const getFrontStoreOrderId = async (orderId) => {
  return axios
    .get(`/apiv1/front/store/order/${orderId}`)
    .then((res) => res.data)
    .catch((err) => console.error(err))
}
