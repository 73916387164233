import { ElasticSearchQuery } from 'api'
import axios from 'axios'
const host = process.env.NEXT_PUBLIC_API_HOST

// GET : 상품검색
export const getFrontProduct = async (data, env = 'client') => {
  // data = {
  //   s_lang,
  //   offset,
  //   s_keyword
  // }

  const params = {
    s_order1: 'T10.sorting',
    s_order2: 'desc',
    s_status: 'normal',
    ...data,
  }

  return await axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/product`, { params })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// GET : 엘라스틱 서치(상품)
export const postElasticSearchProduct = async (data, env = 'client') => {
  const query = new ElasticSearchQuery(
    'product',
    data.offset,
    data.s_keyword,
    data.s_lang
  )

  return await axios
    .post(
      `${
        env === 'client'
          ? '/elastic'
          : process.env.NEXT_PUBLLIC_ELASTIC_SEARCH_HOST
      }/product_index/_search`,
      query
    )
    .then((res) => {
      const result = res.data.hits.hits

      let arr = []
      result.map((item) => arr.push(item._source))
      return arr
    })
    .catch((err) => err)
}

// GET : 특정 상품 조회
export const getFrontProductId = async (data, env = 'client') => {
  // const data = {
  //   s_lang
  //   productId
  // }

  const { productId, ...params } = data

  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/product/${productId}`, {
      params,
    })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// GET : 상품옵션 조회
export const getFrontProductOption = async (product_id, env = 'client') => {
  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/product/option`, {
      params: { product_id },
    })
    .then((res) => res.data.data)
    .catch((err) => err.response.status)
}

// GET : 상품옵션 타이틀 조회
export const getFrontProductOptionTitle = async (
  product_id,
  env = 'client'
) => {
  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/product/option/title`, {
      params: { product_id },
    })
    .then((res) => res.data.data)
    .catch((err) => err.response.status)
}

// GET : 상품 북마크 조회
export const getFrontUserBookmarkProduct = async (
  data,
  token,
  env = 'server'
) => {
  // const data = {
  //   s_store_id,
  //   s_product_id
  // }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { ...data },
  }

  return axios
    .get(
      `${env === 'server' ? host : '/apiv1'}/front/user/bookmark/product`,
      config
    )
    .then((res) => res.data)
    .catch((err) => err)
}
