export const splitKeyword = (target, keyword) => {
  const targetLen = target.length
  const keyLen = keyword.length

  const index = target.indexOf(keyword)

  if (index === 0) {
    // 시작점에 있는 경우
    return (
      <>
        <span style={{ color: '#ff4a52' }}>{keyword}</span>
        {target.split(keyword)[1]}
      </>
    )
  } else if (index === targetLen - keyLen) {
    // 마지막에 있는 경우
    return (
      <>
        {target.split(keyword)[0]}
        <span style={{ color: '#ff4a52' }}>{keyword}</span>
      </>
    )
  } else if (index == -1) {
    // 검색어가 포함되어 있지 않은 경우
    return target
  } else {
    // 중간의 경우
    return (
      <>
        {target.split(keyword)[0]}
        <span style={{ color: '#ff4a52' }}>{keyword}</span>
        {target.split(keyword)[1]}
      </>
    )
  }
}
