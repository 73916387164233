import axios from 'axios'
const host = process.env.NEXT_PUBLIC_API_HOST

// GET : 장바구니 조회
export const getFrontCart = async (data, env = 'client') => {
  // const data = {
  //   token,
  //   s_lang
  // }

  const { token, s_lang = 'ko' } = data

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { s_lang },
  }

  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/cart`, config)
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : 장바구니 추가 (Client)
export const postFrontCart = async (data) => {
  // data = {
  //   token
  //   product_id,
  //   quantity
  // }

  const { token, ...params } = data
  const headers = { Authorization: `Bearer ${token}` }

  return axios
    .post('/apiv1/front/cart', params, { headers })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// PUT : 장바구니 수량 변경 (Client)
export const putFrontCartId = async (data) => {
  const { token, cart_id, quantity } = data
  const headers = { Authorization: `Bearer ${token}` }

  return axios
    .put(`/apiv1/front/cart/${cart_id}`, { quantity }, { headers })
    .then((res) => res)
    .catch((err) => err.response.status)
}

// DELETE : 장바구니 삭제 (Client)
export const deleteFrontCartId = async (data) => {
  // data = {
  //   token,
  //   cart_id
  // }

  const { token, cart_id } = data
  const headers = { Authorization: `Bearer ${token}` }

  return axios
    .delete(`/apiv1/front/cart/${cart_id}`, { headers })
    .then((res) => res)
    .catch((err) => err.response.status)
}

// POST : 장바구니 전체 삭제 - 다른 상점의 상품 추가할시 (client)
export const postFrontCartAllDelete = async (token) => {
  const headers = { Authorization: `Bearer ${token}` }
  return axios
    .post(`/apiv1/front/cart/allDelete`, { headers })
    .then((res) => res)
    .catch((err) => err.response.status)
}

// POST : 장바구니 옵션 저장 (Client)
export const postFrontCartOption = async (data) => {
  // data = {
  //   token,
  //   cart_id,
  //   product_option_id
  // }

  const { token, ...params } = data
  const headers = { Authorization: `Bearer ${token}` }

  return axios
    .post('/apiv1/front/cart/option', params, { headers })
    .then((res) => res)
    .catch((err) => err.response.status)
}

// POST : 장바구니 총금액 호출
export const postFrontCartTotal = async (data, env = 'client') => {
  // const data = {
  //   token
  //   promotion_id,
  //   promotion_price,
  //   s_channel,
  //   s_lang
  // }

  const { token, ...params } = data
  const headers = { Authorization: `Bearer ${token}` }

  return axios
    .post(`${env === 'client' ? '/apiv1' : host}/front/cart/total`, params, {
      headers,
    })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : 비회원 장바구니 추가 (Client)
export const postFrontRwgCart = async (data) => {
  // data = {
  //   not_login_user_id
  //   product_id,
  //   quantity
  // }

  return axios
    .post('/apiv1/front/rwgcart', data)
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// GET : 비회원 장바구니 조회
export const getFrontRwgCart = async (data, env = 'client') => {
  // const data = {
  //   s_not_login_user_id,
  //   s_lang
  // }

  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/cart`, { params: data })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}
