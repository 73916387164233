import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createWrapper, HYDRATE } from 'next-redux-wrapper'
import gps from './gps'
import auth from './auth'
import cart from './cart'
import wishlist from './wishlist'

const rootReducer = combineReducers({
  gps,
  auth,
  cart,
  wishlist,
})

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      gps,
      auth,
      cart,
      wishlist,
    }
    return nextState
  } else {
    return rootReducer(state, action)
  }
}

const makeStore = () =>
  configureStore({ reducer, devTools: process.env.NODE_ENV !== 'production' })

const wrapper = createWrapper(makeStore)

export default wrapper
