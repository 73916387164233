import axios from 'axios'
const host = process.env.NEXT_PUBLIC_API_HOST

// GET : 위시리스트 조회
export const getFrontUserBookmark = async (data, env = 'client') => {
  // data = {
  //   token,
  //   s_lang
  // }

  const { token, s_lang = 'ko' } = data
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      s_lang,
      limit: 300,
    },
  }

  return axios
    .get(`${env === 'client' ? '/apiv1' : host}/front/user/bookmark`, config)
    .then((res) => res.data)
    .catch((err) => err.response.status)
}

// POST : 위시리스트 등록or삭제 (Client)
export const postFrontUserBookmark = async (data) => {
  // 상점일 경우
  // data = { store_id }
  // 상품일 경우
  // data = { store_id, product_id }

  const { token, ...params } = data
  const headers = { Authorization: `Bearer ${token}` }

  return axios
    .post('/apiv1/front/user/bookmark', params, { headers })
    .then((res) => res.data)
    .catch((err) => err.response.status)
}
