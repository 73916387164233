import Link from 'next/link'

// live-event 상황별/언어별 메세지 변환
export const switchReview = (data, lang) => {
  const {
    event_where,
    event_action,
    location_translate_name2: location,
    translate_store_name: store_name,
    translate_store_branch_name: branch_name,
    translate_product_name: product,
    product_id,
    translate_promotion_name: promotion,
    translate_theme_name: theme,
    store_id,
  } = data

  const store = `${store_name}${branch_name ? `(${branch_name})` : ''}`

  const target = {
    store: {
      index: {
        ko: <p>{location}의 상점을 검색하고 있습니다.</p>,
        en: <p>{location}의 상점을 검색하고 있습니다.</p>,
        ja: <p>{location}의 상점을 검색하고 있습니다.</p>,
        'zh-Hans': <p>{location}의 상점을 검색하고 있습니다.</p>,
        es: <p>{location}의 상점을 검색하고 있습니다.</p>,
      },
      show: {
        ko: (
          <p>
            {location}의 <span className="emphasize">{store}</span>을(를)
            방문하였습니다.
          </p>
        ),
        en: (
          <p>
            This user visited <span className="emphasize">{store}</span> in{' '}
            {location}
          </p>
        ),
        ja: (
          <p>
            {location}にある <span className="emphasize">{store}</span>
            を訪問しました。
          </p>
        ),
        'zh-Hans': (
          <p>
            您去过{location} <span className="emphasize">{store}</span>
          </p>
        ),
        es: (
          <p>
            This user visited <span className="emphasize">{store}</span> in{' '}
            {location}
          </p>
        ),
      },
    },
    product: {
      index: {
        ko: (
          <p>
            {location}에 있는 <span className="emphasize">{store}</span>의
            메뉴판을 보고 있습니다
          </p>
        ),
        en: (
          <p>
            This user is viewing the menu list of{' '}
            <span className="emphasize">{store}</span> in {location}
          </p>
        ),
        ja: (
          <p>
            <span className="emphasize">{store}</span>にある {location}
            のメニューリストを見ています。
          </p>
        ),
        'zh-Hans': (
          <p>
            您当前在查阅 <span className="emphasize">{store}</span> {location}
            的菜单。
          </p>
        ),
        es: (
          <p>
            This user is viewing the menu list of{' '}
            <span className="emphasize">{store}</span> in {location}
          </p>
        ),
      },
      show: {
        ko: (
          <p>
            {location}에 위치한 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 보고 있습니다.
          </p>
        ),
        en: (
          <p>
            This user is viewing{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            at <span className="emphasize">{store}</span> in {location}.
          </p>
        ),
        ja: (
          <p>
            {location}にある<span className="emphasize">{store}</span>の商品、
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            を見ています。
          </p>
        ),
        'zh-Hans': (
          <p>
            您当前在查阅{location} <span className="emphasize">{store}</span>的
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            。
          </p>
        ),
        es: (
          <p>
            This user is viewing{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            at <span className="emphasize">{store}</span> in {location}.
          </p>
        ),
      },
    },
    order: {
      cancel: {
        ko: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            주문을 취소했습니다
          </p>
        ),
        en: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            주문을 취소했습니다
          </p>
        ),
        ja: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            주문을 취소했습니다
          </p>
        ),
        'zh-Hans': (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            주문을 취소했습니다
          </p>
        ),
        es: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            주문을 취소했습니다
          </p>
        ),
      },
      cancel_partial: {
        ko: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            주문을 취소했습니다
          </p>
        ),
        en: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            주문을 취소했습니다
          </p>
        ),
        ja: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            주문을 취소했습니다
          </p>
        ),
        'zh-Hans': (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            주문을 취소했습니다
          </p>
        ),
        es: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>{' '}
            주문을 취소했습니다
          </p>
        ),
      },
      store: {
        ko: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 주문했습니다.
          </p>
        ),
        en: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 주문했습니다.
          </p>
        ),
        ja: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 주문했습니다.
          </p>
        ),
        'zh-Hans': (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 주문했습니다.
          </p>
        ),
        es: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 주문했습니다.
          </p>
        ),
      },
    },
    cart: {
      store: {
        ko: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 장바구니에 담았습니다.
          </p>
        ),
        en: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 장바구니에 담았습니다.
          </p>
        ),
        ja: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 장바구니에 담았습니다.
          </p>
        ),
        'zh-Hans': (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 장바구니에 담았습니다.
          </p>
        ),
        es: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 장바구니에 담았습니다.
          </p>
        ),
      },
      update: {
        ko: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 장바구니에 수량을 변경했습니다.
          </p>
        ),
        en: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 장바구니에 수량을 변경했습니다.
          </p>
        ),
        ja: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 장바구니에 수량을 변경했습니다.
          </p>
        ),
        'zh-Hans': (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 장바구니에 수량을 변경했습니다.
          </p>
        ),
        es: (
          <p>
            {location}의 <span className="emphasize">{store}</span>의{' '}
            <Link href={`/food/${store_id}/product/${product_id}`}>
              <span className="emphasize">{product}</span>
            </Link>
            을(를) 장바구니에 수량을 변경했습니다.
          </p>
        ),
      },
    },
    promotion: {
      index: {
        ko: <p>프로모션을 보고 있습니다.</p>,
        en: <p>프로모션을 보고 있습니다.</p>,
        ja: <p>프로모션을 보고 있습니다.</p>,
        'zh-Hans': <p>프로모션을 보고 있습니다.</p>,
        es: <p>프로모션을 보고 있습니다.</p>,
      },
      show: {
        ko: <p>{promotion}을(를) 보고 있습니다.</p>,
        en: <p>{promotion}을(를) 보고 있습니다.</p>,
        ja: <p>{promotion}을(를) 보고 있습니다.</p>,
        'zh-Hans': <p>{promotion}을(를) 보고 있습니다.</p>,
        es: <p>{promotion}을(를) 보고 있습니다.</p>,
      },
    },
    theme: {
      index: {
        ko: <p>테마를 보고 있습니다.</p>,
        en: <p>테마를 보고 있습니다.</p>,
        ja: <p>테마를 보고 있습니다.</p>,
        'zh-Hans': <p>테마를 보고 있습니다.</p>,
        es: <p>테마를 보고 있습니다.</p>,
      },
      show: {
        ko: <p>{theme}을(를) 보고 있습니다.</p>,
        en: <p>{theme}을(를) 보고 있습니다.</p>,
        ja: <p>{theme}을(를) 보고 있습니다.</p>,
        'zh-Hans': <p>{theme}을(를) 보고 있습니다.</p>,
        es: <p>{theme}을(를) 보고 있습니다.</p>,
      },
    },
  }

  const validation = [event_where, event_action]

  if (!validation || validation === '') return null
  else {
    if (!target[event_where] || !target[event_where][event_action]) return null
    else return target[event_where][event_action][lang]
  }
}

// live-event 마스킹
export const nameToMasking = (name) => {
  if (!name) return null

  const length = name.length
  const visibleCount = Math.ceil(length / 4)
  const hiddenCount = length - visibleCount * 2

  // 길이가 1이면 hiddenCount -1이 떠서 에러남
  if (name.length === 1) return '*'

  const initial = name.substr(0, visibleCount)
  const masking = String('*').repeat(hiddenCount)
  const last = name.substr(visibleCount * -1, visibleCount)

  if (name.length === 2) reutrn`${initial}${masking}`
  return `${initial}${masking}${last}`
}
