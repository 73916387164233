export const switchLocale = (locale) => {
  switch (locale) {
    case '':
    case 'ko':
    case 'en':
    case 'ja':
    case 'zh-Hans':
    case 'es':
      return locale
    case 'en-us':
      return 'en'
    case 'zh-cn':
      return 'zh-Hans'
    default:
      return ''
  }
}

export const switchNavigatorLang = (lang) => {
  const substrLang = lang.substr(0, 2)

  switch (substrLang) {
    case 'ko':
    case 'en':
    case 'ja':
      return substrLang
    case 'zh':
      if (lang.toLowerCase() === 'zh-tw') {
        return 'zh-Hant'
      } else {
        return 'zh-Hans'
      }
    default:
      return 'en'
  }
}
