// 할인금액 계산
export const promotionRate = (price, rate, max) => {
  const float = parseFloat(rate.replace('%', '') / 100)
  return Math.round(
    Math.min(max ?? price * float, price * float)
  ).toLocaleString('ko-kr')
}

// 객체 비어있는지 확인
export const isEmptyObj = (obj) => {
  for (let key in obj) {
    // if the loop has started, there is a property
    return false
  }
  return true
}
